import React from "react";
import "./Footer.css"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
function Footer(){

  return(
  <>
    <footer className="footer-main">
      <div className="footer-container">
      <div className="footer-section">
        <h4>Explore</h4>
        <ul>
          <li className="footer-section-list-item"><a href="/">Home</a></li>
          <li className="footer-section-list-item-company"><a href="https://justmindwork.com/">Our Company</a></li>
          <li className="footer-section-list-item-privacy-policy"><a href="/privacy-policy">Privacy Policy</a></li>
        </ul>
      </div>
      <div className="footer-section">
        <h4>Services</h4>
        <ul>
          <li className="footer-section-list-item"><a href="/inventory">Inventory</a></li>
          <li className="footer-section-list-item"><a href="/sale">Sales</a></li>
          <li className="footer-section-list-item"><a href="/purchase">Purchases</a></li>
          <li className="footer-section-list-item"><a href="/purchase">Tax management</a></li>
          <li className="footer-section-list-item"><a href="/category">Category</a></li>
          <li className="footer-section-list-item"><a href="/coupon">Coupon</a></li>
          <li className="footer-section-list-item"><a href="/unit">Unit</a></li>
        </ul>
      </div>

      <div className="footer-section">
        <h4>Follow us</h4>
        <ul>
          <li className="footer-section-list-social-item" ><a href="https://www.facebook.com/profile.php?id=61562135546039"><FontAwesomeIcon icon={faFacebook} /> Facebook</a></li>
          <li className="footer-section-list-social-item"><a href="https://www.linkedin.com/company/74734558/admin/dashboard/"><FontAwesomeIcon icon={faLinkedinIn} /> Linkedin</a></li>
          <li className="footer-section-list-social-item"><a href="https://www.instagram.com/billeasy.jmw/"> <FontAwesomeIcon icon={faInstagram} className="footer-social-symbol"/> Instagram</a></li>
        </ul>
      </div>

      <div className="footer-section">
        <h4>Get in touch</h4>
        <ul>
          <li className="footer-section-list-item"><i className="fas fa-envelope"></i> contact@justmindwork.com</li>
          <li className="footer-section-list-item"><i className="fas fa-envelope"></i> business@justmindwork.com</li>
          <li className="footer-section-list-item"><i className="fas fa-envelope"></i> sales@justmindwork.com</li>
          <li className="footer-section-list-item"><i className="fas fa-phone"></i> +91-9644780306</li>
        </ul>
      </div>

      <div className="footer-section">
        <h4>Just Mind Work Pvt Ltd</h4>
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3683.303459679105!2d75.86385!3d22.72397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962e30c50000001%3A0x469c14ff0cfb870f!2sYour%20Location%2C%20Indore%2C%20Madhya%20Pradesh!5e0!3m2!1sen!2sin!4v1617547275731!5m2!1sen!2sin"
            width="100%"
            height="150"
            allowfullscreen=""
            loading="lazy"
            title="Google Maps Location"
          ></iframe>
        </div>
        <p>
          407, Satguru Parinay, Scheme 54, opposite C21 Mall, PU4, AB Road, Indore, Madhya Pradesh 452011
        </p>
      </div>
      </div>

      <div className="footer-bottom">
        <span className="footer-copyright-title">Copyright © Just Mind Work Pvt Ltd</span>
        <span className="footer-second-title">Powered by <Link to="https://www.justmindwork.com" target="_blank" rel="noopener noreferrer" className="footer-company-link">justmindwork</Link></span>
      </div>
    </footer>
  </>
  );
}

export default Footer;
