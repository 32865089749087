import React from "react";
import "./Features.css"
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
function Features() {
  return (
    <>
      <Header />
      <div className="features-main-container">
        <div className="features-container-header">
          <span className="features-header-title">Billing Software Features</span>
        </div>
        <hr className="features-header-bottom-line" />
        <div className="features-subcontainer">
          <div className="features-sub-container-level-first">
            <div className="features-first-level-summary-left">
              <span className="features-summary-title">Invoicing</span>
              <span className="features-summary-container">
                Maintain a full sales pipeline by continuous prospecting through the generation of custom quotes and invoices.
                Generate quotes in just a few minutes to save your valuable time.
              </span>
              <span className="features-summary-container-subclass">
                Simply convert the quotes directly into invoice and send to clients after their approval.
              </span>
            </div>
            <div className="features-first-level-summary-right">
              <img src={require('../../Assets/Images/appinvoice.png')} alt="first-level-img" className="feature-first-level-img-class" />
            </div>
          </div>
          <hr className="features-bottom-line"/>

          <div className="features-sub-container-level-second">
            <div className="features-first-level-summary-right">
              <img src={require('../../Assets/Images/appquickinvoice.png')} alt="first-level-img" className="feature-second-level-img-class" />
            </div>
            <div className="features-first-level-summary-right">
              <span className="features-summary-second-title">Quick Invoice</span>
              <span className="features-summary-second-container">
                Speed up the data entering process
              </span>
              <span className="features-summary-second-container-subclass">
                Efficient data entry for large volumes of similar invoices.
              </span>
              <span className="features-summary-second-container-subclass">
                Invoice or retail invoice can be switched on in the same window by an easy toggle.
              </span>
            </div>
          </div>
          <hr className="features-bottom-line" />
          
          <div className="features-sub-container-level-third">
            <div className="features-first-level-summary-left">
              <span className="features-summary-title">Point Of Sale Billing(POS)</span>
              <span className="features-summary-container">
                Make your billing fast & accurate with Point Of Sale Billing. Generate bills/invoices, receipts, orders from any mobile/tablet & make 
                your customer experience just fantastic avoiding traditional checkout counters.
              </span>
              <span className="features-summary-container-subclass">
                Collect customer data at POS, track customer transaction history. 
                Issue bill in seconds & get paid instantly at times of peak sales. 
                With barcode scanning, make billing even more faster along with automatic inventory syncing.
              </span>
            </div>
            <div className="features-first-level-summary-right">
              <img src={require('../../Assets/Images/appdashboard.png')} alt="first-level-img" className="feature-first-level-img-class" />
            </div>
          </div>
          <hr className="features-bottom-line" />

          <div className="features-sub-container-level-fourth">
            <div className="features-first-level-summary-right">
              <img src={require('../../Assets/Images/appinventory.png')} alt="first-level-img" className="feature-second-level-img-class" />
            </div>
            <div className="features-first-level-summary-right">
              <span className="features-summary-second-title">Inventory / Warehouse Management</span>
              <span className="features-summary-second-container">
                Have a complete real-time tracking of inventory and analyse which stock is under which branch/warehouse. 
                Track stock based on batches & serial numbers.
              </span>
              <span className="features-summary-second-container-subclass">
                Ensure optimum stock for sales, avoid stock outs. Know high selling stock, set reorder levels & reorder at the right time. 
                Transfer stock across warehouses in case of shortage & replenish the stock quickly.
              </span>
            </div>
          </div>
          <hr className="features-bottom-line" />

          <div className="features-sub-container-level-fifth">
            <div className="features-first-level-summary-left">
              <span className="features-summary-title">Stock Details</span>
              <span className="features-summary-container">
                Stock items are defined as material resources that are held in storerooms and issued to activities that require the materials to be completed. 
                The stock item record determines whether or not the type of stock can be purchased, repaired, tracked, and so on.
              </span>
            </div>
            <div className="features-first-level-summary-right">
              <img src={require('../../Assets/Images/appstock.png')} alt="first-level-img" className="feature-first-level-img-class" />
            </div>
          </div>
          <hr className="features-bottom-line" />

          <div className="features-sub-container-level-sixth">
            <div className="features-first-level-summary-right">
              <img src={require('../../Assets/Images/appbarcode.png')} alt="first-level-img" className="feature-second-level-img-class" />
            </div>
            <div className="features-first-level-summary-right">
              <span className="features-summary-second-title">Bar Code Generation, Printing & Scanning</span>
              <span className="features-summary-second-container">
                Generate & Print your own customised barcodes stickers in Output Books to make your data entry quicker. 
                Scan the Item barcode using the scanner and speed up your billing process.
              </span>
            </div>
          </div>
          <hr className="features-bottom-line" />

          <div className="features-sub-container-level-seventh">
            <div className="features-first-level-summary-left">
              <span className="features-summary-title">Promotion</span>
              <span className="features-summary-container">
                An Item Promotion is an automated paid feature that allows more visibility for your items.
                When you promote an item, it is displayed to more members in their item feed and catalog search results.
              </span>
            </div>
            <div className="features-first-level-summary-right">
              <img src={require('../../Assets/Images/apppromotion.png')} alt="first-level-img" className="feature-first-level-img-class" />
            </div>
          </div>
          <hr className="features-bottom-line" />

          <div className="features-sub-container-level-eighth">
            <div className="features-first-level-summary-right">
              <img src={require('../../Assets/Images/apppurchase.png')} alt="first-level-img" className="feature-second-level-img-class" />
            </div>
            <div className="features-first-level-summary-right">
              <span className="features-summary-second-title">Purchase Bill</span>
              <span className="features-summary-second-container">
                Track and record the payments of your purchase made by your business in Output Books. Authorize your purchase. 
                Maintain Purchase bill for each and every purchase.
              </span>
            </div>
          </div>
          <hr className="features-bottom-line" />

          <div className="features-sub-container-level-ninth">
            <div className="features-first-level-summary-left">
              <span className="features-summary-title">UPI Integration</span>
              <span className="features-summary-container">
                Link your Bank UPI code in Billeasy and print QR code in Invoice. 
                Issue printed invoice to your customers to scan QR code and pay instantly from their mobile payment apps.
              </span>
            </div>
            <div className="features-first-level-summary-right">
              <img src={require('../../Assets/Images/appupi.png')} alt="first-level-img" className="feature-first-level-img-class" />
            </div>
          </div>
          <hr className="features-bottom-line" />

          <div className="features-sub-container-level-tenth">
            <div className="features-first-level-summary-right">
              <img src={require('../../Assets/Images/receivemsg.png')} alt="first-level-img" className="feature-second-level-img-class" />
            </div>
            <div className="features-first-level-summary-right">
              <span className="features-summary-second-title">Email / SMS / Whatsapp Integration</span>
              <span className="features-summary-second-container">
                Integrate Email / SMS / Whatsapp with billeasy & send bills too customers directly from billeasy.
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Features;
