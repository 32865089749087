import React from "react";
import "./Unit.css"
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
function Unit(){
  return(
    <>
      <Header />
        <div className="unit-main-container">
          <div className="unit-sub-container">
            <div className="unit-header-container">
              <span className="unit-header-title">
                Item Unit
              </span>
            </div>
            <hr />
            <div>
              <span className="item-unit-defination">
                You define information about each item's units of measure, such as each, box, and case, to allow the system to perform putaway, picking, and replenishment of that item. 
                You define the item's units of measure when you create the Item Master record for the item.
              </span>
            </div>
            <div>
            <div className="unit-list-grp">Units:</div>
              <hr />
              <ul>
              <li className="unit-menu-name">gram</li>
              <li className="unit-menu-name">kilogram</li>
              <li className="unit-menu-name">liter</li>
              <li className="unit-menu-name">meter</li>
              <li className="unit-menu-name">cm</li>
              <li className="unit-menu-name">piece</li>
              <li className="unit-menu-name">bundle</li>
              </ul>
            </div>
            <div className="unit-create-process-group">
              <span className="unit-question-header">How to create various Item Units?</span>
              <hr />
              <ul>
              <li className="unit-list-process">Open Manage module<span>&gt;</span>Unit.</li>
              <img src={require('../../Assets/Images/appunit.png')} alt="first-img" className="unit-first-img-class" />
              <li className="unit-list-process">Click “Add Unit” and specify the name of the unit.</li>
              <img src={require('../../Assets/Images/unitpop.png')} alt="second-img" className="unit-second-img-class" />
              <li className="unit-list-process">Enter the unit name.</li>
              <li className="unit-list-process">then unit is created.</li>
              </ul>
            </div>
          </div>
        </div>
      <Footer />
    </>
  );
}

export default Unit;
