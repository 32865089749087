import React from "react";
import "./Category.css"
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
function Category(){
  return(
    <>
      <Header />
      <div className="category-main-container">
        <div className="category-sub-container">
          <div className="category-header-container">
            <span className="category-header-title">
              Item Categories
            </span>
          </div>
          <hr />
          <div>
            <span className="item-category-defination">
              An item category is a way to organize items into groups based on different criteria.
              Item categories can help you find items by grouping them in different ways, such as by type, color, or size.
            </span>
          </div>
          <div>
            <div className="category-list-grp">Categories:</div>
            <hr />
            <ul>
              <li className="category-menu-name">Veg</li>
              <li className="category-menu-name">Non-Veg</li>
              <li className="category-menu-name">Drink</li>
              <li className="category-menu-name">Starter</li>
              <li className="category-menu-name">Snakes</li>
            </ul>
          </div>
          <div className="category-create-process-group">
            <span className="category-question-header">How to create various Item Categories?</span>
            <hr />
            <ul>
              <li className="category-list-process">Open Manage module<span>&gt;</span>Categories.</li>
              <img src={require('../../Assets/Images/appcategory.png')} alt="first-img" className="category-first-img-class" />
              <li className="category-list-process">Click “Add Category” and specify the name of the category.</li>
              <img src={require('../../Assets/Images/categorypop.png')} alt="second-img" className="category-second-img-class" />
              <li className="category-list-process">Choose the Parent category.</li>
              <li className="category-list-process">Select the attributes for your category(if created already). If not, click Attributes and create desired attributes for your categories.</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Category;
