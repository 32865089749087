import React from "react";
import "./Sale.css"
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
function Sale() {
  return (
    <>
    <Header />
      <div className="sale-main-container">
        <section className="sale-container-section">
          <div className="sale-container-first-div">
            <h2 className="sale-container-header">
              <span className="sale-header-title">
                Supercharge Your Sales Management with Billeasy
              </span>
            </h2>
            <hr />
            <br />
            <p className="sale-first-paragraph">
              Billeasy significantly enhances your sales management capabilities with its advanced and user-friendly tools. 
              Our platform allows you to create and send professional invoices quickly, ensuring that your billing process is both efficient and accurate. 
              The system supports customizable invoice templates, enabling you to add your branding and personalize the invoices according to your business needs. 
              You can track your sales performance in real-time with detailed reports that provide valuable insights into your revenue streams, customer buying patterns, and sales trends. 
              Billeasy also helps you manage customer data effectively, keeping comprehensive records of transaction histories and customer preferences. This feature not only improves customer service but also allows you to tailor your sales strategies to meet customer needs better.
              Additionally, our system integrates with various payment gateways, making it easy for customers to pay online, which can help speed up payment cycles and improve cash flow. The streamlined sales management process ensures that you can handle transactions smoothly,
              maintain accurate records, and stay organized, thereby focusing more on expanding your business.
            </p>
            <h2>
              &nbsp;
            </h2>
            <br />
            <div className="sale-container-img-div">
              <img src={require('../../Assets/Images/appsale.png')} alt="sale-img" className="sale-img-class" />
            </div>
            <h2>
              &nbsp;
            </h2>
            <br />
            <div className="sale-class-second-header">
              <span className="sale-class-second-header-title">Summary</span>
            </div>
            <hr />
            <p className="sale-second-paragraph">
              A bill of sale refers to a legal document. This agreement documents the exchange between the buyer and seller that transfers ownership of a property or other personal possession to the purchaser. 
              This legal document is issued following the completion of the transactions.
            </p>
          </div>
        </section>
      </div>
    <Footer />
    </>
  );
}

export default Sale;
