import React from "react";
import "./Purchase.css"
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
function Purchase() {
  return (
    <>
      <Header />
      <div className="purchase-main-container">
        <section className="purchase-container-section">
          <div className="purchase-container-first-div">
            <h2 className="purchase-container-header">
              <span className="purchase-header-title">
                Unlock Hassle-Free Purchasing with Billeasy
              </span>
            </h2>
            <hr />
            <br />
            <p className="purchase-paragraph-first">
              Streamlining your purchasing process is made effortless with Billeasy.
              Our platform provides tools to efficiently manage vendor relationships and track purchase orders from initiation to completion.
              You can monitor expenditures closely, keeping accurate records of all purchases, ensuring that you stay within budget and make informed procurement decisions.
              Billeasy allows you to set up approval workflows, which adds an extra layer of control and accountability to your purchasing process. The software supports automated matching of purchase orders with invoices and goods received, reducing manual errors and discrepancies.
              With detailed analytics and reporting, you can gain insights into your spending patterns, supplier performance, and purchasing trends, enabling you to negotiate better terms with vendors and optimize your supply chain. By consolidating all your purchasing information in one place,
              Billeasy helps you maintain strong supplier relationships and ensure timely payments, which are critical for maintaining a smooth supply chain. These features collectively ensure that your procurement strategy is optimized, resources are efficiently utilized, and operational efficiency is maximized.
            </p>
            <h2>
              &nbsp;
            </h2>
            <br />
            <div className="purchase-container-img-div">
              <img src={require('../../Assets/Images/apppurchase.png')} alt="purchase-img" className="purchase-img-class" />
            </div>
            <h2>
              &nbsp;
            </h2>
            <div className="purchase-class-second-header">
              <span className="purchase-class-second-header-title">Summary</span>
            </div>
            <hr />
            <p className="purchase-paragraph-second">
              A purchase bill comprises information such as a unique reference number, the date of the bill, product details,
              the name and full contact information of the individual involved, the amount of taxes due if any, and much more.
              The purchase bill indicates the sum that both the buyer and the seller have agreed to.
            </p>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Purchase;
