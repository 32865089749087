import React, { useState } from "react";
import "./Home.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link, useNavigate } from "react-router-dom";

function Home() {
  const [isRotated, setIsRotated] = useState(false);
  const handleEnter = () => setIsRotated(true);
  const handleLeave = () => setIsRotated(false);
  const handleTouchStart = () => setIsRotated(true);
  const handleTouchEnd = () => setIsRotated(false);
  const navigate = useNavigate();

  const handleMoreFeatures = () => {
    navigate("/features")
  }
  return (
    <>
      <Header />
      <div className="home-main-container">
        <div className="home-container-level-first">
          <div className="bubble bubble-1">
            <img src={require('../../Assets/Images/billlogo.png')} alt="bubble-1" />
          </div>
          <div className="bubble bubble-2">
            <img src={require('../../Assets/Images/billlogo.png')} alt="bubble-2" />
          </div>
          <div className="bubble bubble-3">
            <img src={require('../../Assets/Images/billlogo.png')} alt="bubble-3" />
          </div>
          <div className="bubble bubble-4">
            <img src={require('../../Assets/Images/billlogo.png')} alt="bubble-4" />
          </div>
          <div className="bubble bubble-5">
            <img src={require('../../Assets/Images/billlogo.png')} alt="bubble-5" />
          </div>
          <div className="animation-shape shape-triangle animation--rotating-diagonal">
            <div className="animation--rotating"></div>
          </div>
          <div className="home-container-level-first-grp">
            <span className="home-container-first-title">Unlock your business's full potential with Billeasy</span>
          </div>
          <div className="home-container-new-level-first">
            <span className="home-container-new-title">Smart Billing Software to Grow Business</span>
            <div className="home-new-container-second-grp">
              <span className="home-new-container-second-title">Create professional Invoices, Track Dues & Manage your accounts easily.</span>
            </div>
          </div>
          {/* <div className="home-container-second-title">
            <span className="home-container-second-title-content">Connect with our support team to discuss your Business needs and get expert guidance to grow your business</span>
          </div>
          <div className="home-container-third-title">
            <span className="home-container-third-title-content">With "Billeasy" let your business on fire</span>
          </div> */}
          <div className="desktop-dashboard-image">
            <img src={require('../../Assets/Images/desktop-dashboard.png')} alt="desktop-img" className="home-desktop-img-class" />
          </div>
        </div>

        <div className="home-features-container-group">
          <div className="home-features-container">
            <div id="home-feature-app">
              <div className="home-key-features">
                <span className="home-key-feature-heading">Key Features of GST Invoicing Software</span>
              </div>
              <div className="home-key-features-menu-grp">
                <div className="home-key-features-first-row">
                  <div className="feature-item">
                    <img src={require('../../Assets/Images/invoice.png')} alt="Invoices"></img>
                    <span className="feature-title">Invoices/Retail Invoices</span>
                    <span className="feature-paragraph">
                      Effortlessly create and customize professional invoices instantly.
                      Stay on top of receipts and dues, and speed up payments with recurring billing.
                    </span>
                  </div>
                  <div className="feature-item">
                    <img src={require('../../Assets/Images/multiple.png')} alt="Branches"></img>
                    <span className="feature-title">Multiple Branches/Location/Franchisee</span>
                    <span className="feature-paragraph">
                      Centrally manage all your branches/warehouses/franchisee sales, accounts & inventory from anywhere on any device. Transfer stock between branches easily.
                    </span>
                  </div>
                  <div className="feature-item">
                    <img src={require('../../Assets/Images/accounting.png')} alt="Accounting"></img>
                    <span className="feature-title">Accounting</span>
                    <span className="feature-paragraph">
                      Simple yet powerful accounting with Journal Entry, Cash/Contra and all standard reports in few clicks. Monitor every financial transaction and stay financially healthy.
                    </span>
                  </div>
                </div>

                <div className="home-key-features-second-row">
                  <div className="feature-item">
                    <img src={require('../../Assets/Images/gstreturn.png')} alt="GST Return"></img>
                    <span className="feature-title">GST Return</span>
                    <span className="feature-paragraph">
                      File all your GST returns on time at the GSTIN portal by exporting GST Reports in JSON format. No additional input work. Easy & Fast GST Return filing than before.
                    </span>
                  </div>
                  <div className="feature-item">
                    <img src={require('../../Assets/Images/financialreport.png')} alt="Financial Reports"></img>
                    <span className="feature-title">Financial Reports</span>
                    <span className="feature-paragraph">
                      Accurate Business analytics by having Drill down reports which lead to better chase of data. View & track financial status of your entire company no matter where you are.
                    </span>
                  </div>
                  <div className="feature-item">
                    <img src={require('../../Assets/Images/stockinventory.png')} alt="Inventory"></img>
                    <span className="feature-title">Inventory</span>
                    <span className="feature-paragraph">
                      Simple Inventory system to track & analyze your item stock in different warehouses, branches Centrally. Serialized Inventory to track individual stock units & avoid stock-outs.
                    </span>
                  </div>
                </div>

                <div className="home-key-features-third-row">
                  <div className="feature-item">
                    <img src={require('../../Assets/Images/barcode.png')} alt="GST Return"></img>
                    <span className="feature-title">Barcode</span>
                    <span className="feature-paragraph">
                      Design your own bar-code labels for the stock-items.Print and scan barcodes for super fast billing at checkouts. Move to digital billing by using mobile-barcode scanning.
                    </span>
                  </div>
                  <div className="feature-item">
                    <img src={require('../../Assets/Images/bank.png')} alt="Financial Reports"></img>
                    <span className="feature-title">Bank Reconciliation</span>
                    <span className="feature-paragraph">
                      Reconcile your bank statement with the accounting transactions records and identify errors or fradulent activity(if any) and maintain a better financial health of your business.
                    </span>
                  </div>
                  <div className="feature-item">
                    <img src={require('../../Assets/Images/posbill.png')} alt="Inventory"></img>
                    <span className="feature-title">POS Billing</span>
                    <span className="feature-paragraph">
                      Increase your billing efficiency and auto-record your sales, inventory, cash flow with Point Of Sale(POS). Manage your retail business effortlessly and gain customer loyalty.
                    </span>
                  </div>
                </div>

                <div className="home-key-features-fourth-row">
                  <div className="feature-item">
                    <img src={require('../../Assets/Images/einvoice.png')} alt="GST Return"></img>
                    <span className="feature-title">E-Invoice</span>
                    <span className="feature-paragraph">
                      Upload all your B2B invoices electronically & get authenticated through the designated E-Invoice portal directly from Billeasy in a single click. Push Invoices & e-way bills to IRP & receive unique IRN number.
                    </span>
                  </div>
                  <div className="feature-item">
                    <img src={require('../../Assets/Images/ecommerce.png')} alt="Financial Reports"></img>
                    <span className="feature-title">Ecommerce</span>
                    <span className="feature-paragraph">
                      Grow Your Ecommerce Business by building your own website by yourself & start selling online. Manage both online & offline sales using Billeasy. Everything you need for your ecommerce store.
                    </span>
                  </div>
                  <div className="feature-item">
                    <img src={require('../../Assets/Images/bom.png')} alt="Inventory"></img>
                    <span className="feature-title">Bill Of Material(BOM)</span>
                    <span className="feature-paragraph">
                      Organize your manufacturing planning with BOM & ensure that production or assembly goes well at all levels. Link Manufacturing journal with BOM & account the manufacture of finished items.
                    </span>
                  </div>
                </div>

                <div className="home-key-features-fifth-row">
                  <div className="feature-item">
                    <img src={require('../../Assets/Images/accountingproject.png')} alt="GST Return"></img>
                    <span className="feature-title">Project Accounting</span>
                    <span className="feature-paragraph">
                      Track & monitor the financial progress of individual projects including costs, billing & revenue. View current projects and understand if you are on the right track of earning profit in each project.
                    </span>
                  </div>
                  <div className="feature-item">
                    <img src={require('../../Assets/Images/database.png')} alt="Financial Reports"></img>
                    <span className="feature-title">Data Backup & Secure Authentication</span>
                    <span className="feature-paragraph">
                      Auto-backup your data easily. Authenticate the devices for logging in & accessing Books. Allow secure access by approving and unapproving devices attempting to login.
                    </span>
                  </div>
                  <div className="feature-item">
                    <img src={require('../../Assets/Images/upiintegraion.png')} alt="Inventory"></img>
                    <span className="feature-title">UPI Integration</span>
                    <span className="feature-paragraph">
                      Link your bank UPI code in Billeasy and print QR code in Invoice. Issue printed invoice to your customers to scan QR code and pay instantly from their mobile payment apps.
                    </span>
                  </div>
                </div>

                <div className="home-key-features-sixth-row">
                  <div className="feature-item">
                    <img src={require('../../Assets/Images/ratesheet.png')} alt="GST Return"></img>
                    <span className="feature-title">Ratesheet & Discount Rules</span>
                    <span className="feature-paragraph">
                      Predefined rate sheets for offers/special customers and discount rules to provide discount based on item qty. Avoid manual run time calculation by auto-apply of ratesheets in billing.
                    </span>
                  </div>
                  <div className="feature-item">
                    <img src={require('../../Assets/Images/saleagent.png')} alt="Financial Reports"></img>
                    <span className="feature-title">Sales Rep Agent Commission</span>
                    <span className="feature-paragraph">
                      Track and analyze your on-field Sales Representatives performance in live. Set commission percentage for sales rep and auto-generate sales commission journal.
                    </span>
                  </div>
                  <div className="feature-item">
                    <img src={require('../../Assets/Images/multiplerole.png')} alt="Inventory"></img>
                    <span className="feature-title">Multiple User Access & User Roles</span>
                    <span className="feature-paragraph">
                      Work together as a team and make accounting simpler than ever. Set access permission based on user roles and track users’s daily activity in billing/accounting.
                    </span>
                  </div>
                </div>
              </div>

              <div className="home-key-feature-btn-grp">
                <button className="home-more-feature-btn" onClick={handleMoreFeatures}>More Features</button>
              </div>
            </div>
          </div>
        </div>

        <div className="home-middle-container-title-group">
          <div className="middle-container-grp-overlay"></div>
          <div className="home-middle-container-parent-div">
            <div className="middle-container-left-grp">
              <span className="middle-container-left-subtitle-first">
                FILE GST RETURNS EASILY ON
              </span>
              <span className="middle-container-left-subtitle-second">
                YOUR OWN
              </span>
            </div>
            <div className="middle-vl"></div>
            <div className="middle-container-right-grp">
              <span className="middle-container-right-subtitle-first">
                Billeasy app helps to handle all your accounting activities in simple way.
              </span>
              <span className="middle-container-right-subtitle-second">
                Available in <a href="/inventory" className="on-premises-class">On-premises</a> and Cloud.
              </span>
            </div>
          </div>
        </div>

        <div className="home-multiple-business-group">
          <hr className="business-upper-line" />
          <div className="home-multiple-business-title-div">
            <span className="home-multiple-business-title">
              Billing Software for Multiple Business
            </span>
          </div>

          <div className="home-multiple-business-symbol-grp">
            <div className="business-symbol-div">
              <div className="business-symobl-img-grp">
                <img src={require('../../Assets/Images/general.png')} alt="business-img" />
              </div>
              <div className="busines-symbol-name-grp">
                <span className="business-symbol-name">General</span>
              </div>
            </div>

            <div className="business-symbol-div">
              <div className="business-symobl-img-grp">
                <img src={require('../../Assets/Images/pharmacy.png')} alt="business-img" />
              </div>
              <div className="busines-symbol-name-grp">
                <span className="business-symbol-name">Pharmacy</span>
              </div>
            </div>

            <div className="business-symbol-div">
              <div className="business-symobl-img-grp">
                <img src={require('../../Assets/Images/distributor.png')} alt="business-img" />
              </div>
              <div className="busines-symbol-name-grp">
                <span className="business-symbol-name">FMCG Distribution</span>
              </div>
            </div>

            <div className="business-symbol-div">
              <div className="business-symobl-img-grp">
                <img src={require('../../Assets/Images/retail.png')} alt="business-img" />
              </div>
              <div className="busines-symbol-name-grp">
                <span className="business-symbol-name">Retail</span>
              </div>
            </div>

            <div className="business-symbol-div">
              <div className="business-symobl-img-grp">
                <img src={require('../../Assets/Images/garments.png')} alt="business-img" />
              </div>
              <div className="busines-symbol-name-grp">
                <span className="business-symbol-name">Textiles / Garments</span>
              </div>
            </div>

            <div className="business-symbol-div">
              <div className="business-symobl-img-grp">
                <img src={require('../../Assets/Images/vegetables.png')} alt="business-img" />
              </div>
              <div className="busines-symbol-name-grp">
                <span className="business-symbol-name">Sabzi Mandi</span>
              </div>
            </div>
          </div>
        </div>

        <div
          className="home-container-level-second"
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <div className="home-container-level-second-left">
            <img
              src={require('../../Assets/Images/mobile.png')}
              alt="mobile-img"
              className="home-mob-img-class"
              style={{ transform: isRotated ? 'rotate(360deg)' : 'rotate(0deg)' }}
            />
          </div>
          <div className="home-container-level-second-right">
            <div className="home-level-second-heading-title-grp">
              <span className="home-level-second-heading-title">
                Revolutionary software solutions for your business which can handle your day to day activities online & offline.
              </span>
            </div>
            <div className="home-level-second-title-name-grp">
              <span className="home-level-second-title-name">
                {/* As a software provider, we are committed to providing practical and customized solutions to meet your business needs */}
                Use our cutting-edge software to manage your activities with ease and transform your organization.
                Utilize state-of-the-art solutions to streamline your operations and manage your daily activities with ease.
                Ensure seamless and effective operations for your company by implementing a software solution that connects online and offline administration.
                With our feature-rich software, which is customized to fulfill all of your demands, you can transform the way you do business.
              </span>
            </div>
          </div>
        </div>

        <div className="home-container-level-third">
          <div className="level-third-left">
            <span className="level-third-left-content">
              Billeasy can help you to manage your all Inventory,
              Sales, Purchases, & Taxes on your fingertips.
            </span>
          </div>
          <div className="level-third-right">
            <img src={require('../../Assets/Images/inventory.jpg')} alt="inventory-img" className="third-level-img-class" />
          </div>
        </div>

        <div className="home-container-level-fourth">
          <div className="level-fourth-left">
            <span className="level-fourth-left-content">
              We care about your Results
            </span>
            <div className="level-fourth-content-summary">
              <span className="level-fourth-left-sub-content">
                {/* At Billeasy, we put our clients' needs first by providing a feature-rich Report that makes financial data easier to understand.
                We make sure users can easily obtain accurate and comprehensive reports that support their decision-making. */}
                At Billeasy, we are committed to prioritizing our clients' needs by offering a feature-rich reporting system that transforms complex financial data into easily understandable insights.
                Our robust reporting tools are designed with the user in mind, ensuring that accessing accurate and comprehensive reports is a seamless experience. Whether you're analyzing sales trends, monitoring expenses,
                or evaluating overall financial health, our reports provide the clarity and depth necessary to support informed decision-making. We understand that in today's fast-paced business environment,
                having reliable and easily accessible financial data is crucial, and that's why we go the extra mile to ensure our reporting features empower you to make the best choices for your business's future. With Billeasy,
                you can trust that every report is crafted to deliver the precision and detail you need, helping you stay ahead in your financial planning and strategy.
              </span>
            </div>
          </div>
          <div className="level-fourth-right slide-in">
            <img src={require('../../Assets/Images/report.jpg')} alt="report-img" className="fourth-level-img-class" />
          </div>
        </div>

        <div className="home-container-level-fifth">
          <div className="level-fifth-inner-container">
            <div className="level-fifth-inner-div">
              <div className="level-fifth-inner-header">
                <span className="fifth-inner-header-title">Easy Subscription</span>
              </div>
              <div className="fifth-inner-start-div">
                <span className="fifth-inner-start-span">Affordable subscription for all Business</span>
              </div>
              <div className="fifth-card-group-list">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">Complete Invoice Solutions</li>
                  <li className="list-group-item">
                    Get complete access to all our software modules
                  </li>
                  <li className="list-group-item">Unlimited customization</li>
                  <li className="list-group-last-item">24X7 Available Support</li>
                </ul>
              </div>
              <div className="fifth-card-footer">
                <p className="fifth-card-footer-paragraph">Instant setup, satisfied or reimbursed.</p>
                <Link to="/contact-us" className="fifth-card-button">
                  Start now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
