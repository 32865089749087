import React from "react";
import "./Inventory.css"
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
function Inventory() {
  return (
    <>
      <Header />
      <div className="inventory-main-container">
        <section className="inventory-container-section">
          <div className="inventory-container-first-div">
            <h2 className="inventory-container-header">
              <span className="inventory-header-title">
                Streamline Your Business with Billeasy’s Comprehensive Inventory Management Feature
              </span>
            </h2>
            <hr />
            <br />
            <p className="inventory-paragraph-first">
              Billeasy offers real-time tracking of invoice status and payments, along with detailed financial reporting to provide insights into business performance.
            </p>
            <br />
            <div className="inventory-container-img-div">
              <img src={require('../../Assets/Images/appinventory.png')} alt="inventory-img" className="inventory-img-class" />
            </div>
            <h2>
              &nbsp;
            </h2>
            <p className="inventory-paragraph-second">
              Billeasy’s inventory management system is meticulously designed to provide you with unparalleled control over your stock levels.
              Our real-time tracking feature ensures that you can monitor inventory movements as they happen, offering an accurate and up-to-date view of your stock at all times.
              With Billeasy, you can set automatic reordering thresholds, which means you’ll never face the inconvenience of stockouts or overstocking.
              This feature helps in maintaining optimal stock levels and ensuring smooth operational flow. Additionally, our intuitive dashboard offers comprehensive insights into your inventory status, including detailed reports on stock usage,
              turnover rates, and trends. These insights enable you to make informed purchasing and stocking decisions, thereby optimizing your inventory management process. Furthermore, Billeasy’s inventory management system supports multi-location tracking,
              so you can manage inventory across multiple warehouses or retail outlets effortlessly. With these robust features, managing your inventory becomes a seamless, efficient, and highly accurate process, allowing you to focus more on growing your business.
            </p>
            <h2>
              &nbsp;
            </h2>
            <br />
            <div className="inventory-class-third-header">
              <span className="inventory-class-third-header-title">Summary</span>
            </div>
            <hr />
            <p className="inventory-paragraph-third">
              A purchase bill comprises information such as a unique reference number, the date of the bill, product details,
              the name and full contact information of the individual involved, the amount of taxes due if any, and much more.
              The purchase bill indicates the sum that both the buyer and the seller have agreed to.
            </p>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Inventory;
