import React from "react";
import "./Coupon.css"
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

function Coupon(){
  return(
    <>
      <Header />
      <div className="coupon-main-container">
        <div className="coupon-sub-container">
          <div className="coupon-header-container">
            <span className="coupon-header-title">
              Coupon
            </span>
          </div>
          <hr />
          <div>
            <span className="item-coupon-defination">
              A coupon is a piece of printed paper which allows you to pay less money than usual for a product, or to get it free.
            </span>
          </div>
          <div className="coupon-create-process-group">
            <span className="coupon-question-header">How to create coupon?</span>
            <hr />
            <ul>
              <li className="coupon-list-process">Open Manage module<span>&gt;</span>Coupon.</li>
              <img src={require('../../Assets/Images/appcoupon.png')} alt="first-img" className="coupon-first-img-class" />
              <li className="coupon-list-process">Click “Add Coupon” and specify the fill input fields of the coupon.</li>
              <img src={require('../../Assets/Images/couponcreate.png')} alt="second-img" className="coupon-second-img-class" />
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Coupon;
