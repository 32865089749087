import React from "react";
import "./Tax.css"
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
function Tax(){
  return(
    <>
      <div id="tax-super-container">
        <Header />
          <div className="tax-main-container">
            <section className="tax-container-section">
              <div className="tax-container-first-div">
                <h2 className="tax-container-header">
                <span className="tax-header-title">
                  Simplify Tax Management with Billeasy's Smart Solutions
                </span>
                <hr />
                </h2>
                <br />
                <p className="tax-container-paragraph-first">
                  Tax compliance is often a complex and time-consuming task, but Billeasy’s robust tax management features make it straightforward and hassle-free. 
                  Our software automatically calculates taxes based on your local regulations, ensuring accuracy and reducing the risk of errors. You can generate detailed tax reports with just a few clicks, 
                  simplifying the process of filing taxes and keeping track of your obligations. Billeasy stays up-to-date with the latest tax laws and regulations, so you don’t have to worry about compliance issues. 
                  The platform supports multi-tax scenarios, handling various tax rates, exemptions, and jurisdictions seamlessly. This feature is particularly useful for businesses operating in multiple regions with different tax regulations. Additionally, B
                  illeasy provides audit trails and compliance reports that help you stay prepared for any tax audits or inquiries. By automating and simplifying tax calculations, Billeasy frees up valuable time and resources, allowing you to focus on your core business activities. 
                  With our comprehensive tax management system, you can ensure compliance, reduce administrative burdens, and gain peace of mind.
                </p>
                <h2>
                &nbsp;
                </h2>
                <br />
                <div className="tax-container-img-div">
                  <img src={require('../../Assets/Images/apptax.png')} alt="tax-img"  className="tax-rate-img-class"/>
                </div>
                <h2>
                  &nbsp;
                </h2>
                <div className="tax-class-second-header">
                  <span className="tax-class-second-header-title">Summary</span>
                </div>
                <hr />
                <p className="tax-container-paragraph-second">
                  Billeasy provides an integrated suite of features designed to help you manage your business more effectively. 
                  From real-time inventory tracking and efficient sales management to streamlined purchasing processes and robust tax compliance, Billeasy covers all your essential business needs. 
                  Embrace the power of Billeasy and take your business to the next level with our all-in-one management solution.
                </p>
              </div>
            </section>
          </div>
        <Footer />
      </div>
    </>
  );
}

export default Tax;
