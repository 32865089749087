import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';

function HeaderLevelThird() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
      <div className="container-fluid">
        <NavLink to="/" className="navbar-brand">
          <img
            src={require('../../Assets/Images/logo.png')}
            alt="Logo"
            className="d-inline-block align-top"
            style={{ width: '120px', height: 'auto' }}
          />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleMenu}
          aria-controls="navbarNav"
          aria-expanded={menuOpen ? "true" : "false"}
          aria-label="Toggle navigation"
        >
          {menuOpen ? <FaTimes /> : <FaBars />}
        </button>
        <div className={`collapse navbar-collapse ${menuOpen ? 'show full-screen-menu' : ''}`} id="navbarNav">
          {menuOpen && (
            <FaTimes className="close-button" onClick={toggleMenu} />
          )}
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                className="nav-link header-attribute-name"
                activeClassName="active"
                onClick={menuOpen ? toggleMenu : null}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/about-us"
                className="nav-link header-attribute-name"
                activeClassName="active"
                onClick={menuOpen ? toggleMenu : null}
              >
                Powered by JMW
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/inventory"
                className="nav-link header-attribute-name"
                activeClassName="active"
                onClick={menuOpen ? toggleMenu : null}
              >
                Inventory
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/sale"
                className="nav-link header-attribute-name"
                activeClassName="active"
                onClick={menuOpen ? toggleMenu : null}
              >
                Sales
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/purchase"
                className="nav-link header-attribute-name"
                activeClassName="active"
                onClick={menuOpen ? toggleMenu : null}
              >
                Purchase
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/tax"
                className="nav-link header-attribute-name"
                activeClassName="active"
                onClick={menuOpen ? toggleMenu : null}
              >
                Taxes
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/features"
                className="nav-link header-attribute-name"
                activeClassName="active"
                onClick={menuOpen ? toggleMenu : null}
              >
                Features
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/contact-us"
                className="nav-link header-attribute-name"
                activeClassName="active"
                onClick={menuOpen ? toggleMenu : null}
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default HeaderLevelThird;
