import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Home from './Components/Home/Home';
import Inventory from './Components/Inventory/Inventory';
import Sale from './Components/Sale/Sale';
import Purchase from './Components/Purchase/Purchase';
import Tax from './Components/Tax/Tax';
import ContactUs from './Components/ContactUs/ContactUs';
import AboutUs from './Components/AboutUs/AboutUs';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import { Helmet } from 'react-helmet';
import Features from './Components/Features/Features';
import Category from './Components/Category/Category';
import Coupon from './Components/Coupon/Coupon';
import Unit from './Components/Unit/Unit';

function App() {
  return (
    <Router>
      <Helmet>
        <title>billeasy</title>
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/header" element={<Header />} />
        <Route path="/footer" element={<Footer />} />
        <Route path='/inventory' element={<Inventory />} />
        <Route path='/sale' element={<Sale />} />
        <Route path='/purchase' element={<Purchase />} />
        <Route path='/tax' element={<Tax />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/features' element={<Features />} />
        <Route path='/category' element={<Category />} />
        <Route path='/coupon' element={<Coupon />} />
        <Route path='/unit' element={<Unit />} />
      </Routes>
    </Router>
  );
}

export default App;
